<template>
	<a-layout style="height:100%;overflow:hidden">
		<a-layout-header style="height:74px;">
			<Header />
		</a-layout-header>
		<router-view />
	</a-layout>
</template>

<script>
import Header from '../../components/Header.vue';
import {setCube} from '../../until/data_tools'
export default {
	components:{Header},
	data() {
		return {}
	},
	created() {
		this.$http.getUserInfo().then(res=>{
			if(res.userInfo) {
				let userInfo = res.userInfo;
				let cubes = setCube(res.userInfo.metaCubeList);
				this.$store.commit('setCubes',cubes);
				this.$store.commit('setUserInfo',userInfo);
			}
		});
	}
}
</script>